
import { useReducer } from "react";
import { AuthContext } from "./AuthContext";
import { authReducer } from "./authReducer";
import { types } from "../types/types";
/*
const initialState={
    logged:false
}
*/



const init =()=>{
    {/* 
    const user=JSON.parse(localStorage.getItem('user'));
    return {
        logged:!!user,
        user:user,
    }
    */}
    return {
        logged:false,
        user:{},
    }
}


export const AuthProvider =({children})=>{

    const [authState,dispatch]=useReducer(authReducer,{},init);

    const login=(user={})=>{

        
        const action={
            type:types.login,
            payload:user
        }
        //localStorage.setItem('user',JSON.stringify(user));
        dispatch(action);
    }
    const logouth=()=>{
        const action={
            type:types.logout,

        }
        //localStorage.removeItem('user');
        dispatch(action);
    }  

    return (
        
        <AuthContext.Provider value={{...authState,login,logouth}}>
            {children}
        </AuthContext.Provider>

    )

}