import { useState ,useEffect} from 'react';
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
    Alert,
    Button,

    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,

    Stack,
    TextField,
   Typography
  
  } from '@mui/material';

import { onlyNumbers } from '../helpers/funcYup';
import {esUnicoYup,existeCodigoYup} from '../helpers/funcYup';
yup.addMethod(yup.string, "solonum", function (errorMessage) {
  return this.test(`test-codigo solo num`, errorMessage, function (value) {
    const { path, createError } = this;

    return (
      (value && onlyNumbers(value)) ||
      createError({ path, message: errorMessage })
    );
  });
});

  
export const ModalDocumento =({ open, onClose, onSubmit,onEdit,inicial,tableData,titulomod}) => {

    const [errorPrueba, setErrorPrueba] = useState(false);
    const [errorText] = useState("");
    const [initValues] = useState(inicial);

    const [valueIdyup,setValueIdyup] = useState('');  

    yup.addMethod(yup.string, "newexiste", function (errorMessage) {
        
      return this.test(`test-codigo existe`, errorMessage, function (value) {
        const { path, createError } = this;

            return (
              
                (value && !existeCodigoYup(value,valueIdyup,tableData)) || createError({ path, message: errorMessage })
          
            );
      
      });
    
  });    

  yup.addMethod(yup.string, "modexiste", function (errorMessage) {

      return this.test(`test-codigo existe`, errorMessage, function (value) {
        const { path, createError } = this;
      
        
          return (
            (value && !esUnicoYup(tableData,value,valueIdyup.id)) ||
            createError({ path, message: errorMessage })
          );
        
      });
  
  });  
    const checkoutSchema = yup.object().shape({
      codigo: yup.string()
      .solonum("solo numeros")
      .required("required")
      .length(3)
      .newexiste("New ya existe" ).modexiste("Mod ya existe"),

      descripcion: yup.string().required("required"),
      folioinicial:yup.number().test('match', 
                              'debe ser menor o igual a folio final', 
                              function(folioinicial) { 
                                return folioinicial <= this.parent.foliofinal; 
                              }),
      foliofinal:yup.number().required("required"),
      siguiente:yup.number().test('match', 
      'debe ser mayor a folio final', 
      function(siguiente) { 
        return siguiente > this.parent.foliofinal; 
      }),
      sistemaimpresora: yup.string().required("required"),
      });

    const formik = useFormik({
      
      initialValues:initValues,
      validationSchema: checkoutSchema ,
      onSubmit: async (values) => {
        if (values.id==="new" ){

            onSubmit(values);
            onClose();
            /////////////// 
           
        }else{

            //////////////submit 
            console.log("formik edit============>"); 
            console.log(values); 
            onEdit(values);
            onClose();
            
            /////////////// 

        }

        formik.resetForm();
 
      },
    });


    useEffect(() => {
      console.log("Valores iniciales =====>",inicial);
      formik.setFieldValue("id", inicial.id);
      formik.setFieldValue("codigo", inicial.codigo);
      formik.setFieldValue("descripcion",inicial.descripcion);
      formik.setFieldValue("folioinicial",inicial.folioinicial);
      formik.setFieldValue("foliofinal",inicial.foliofinal);
      formik.setFieldValue("siguiente",inicial.siguiente);
      formik.setFieldValue("sistemaimpresora",inicial.sistemaimpresora);


      setErrorPrueba(false);
      setValueIdyup(inicial); 
    
    }, [open]);// eslint-disable-line react-hooks/exhaustive-deps
   
    return (
      <>
        
        <Dialog open={open} maxWidth="md" >
          
          <DialogTitle textAlign="center" > 
         
                 <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                         {titulomod}
                    </Typography>

                </div>
        
                {  errorPrueba   &&
                  <Alert onClose={() => setErrorPrueba(false)} variant="outlined" severity="error">
                      {errorText} This is an error alert — check it out!
                  </Alert>
                }  
          </DialogTitle>
           <DialogContent dividers >
            <form onSubmit={formik.handleSubmit}>
                      <Stack
                      sx={{
                        width: '100%',
                        minWidth: { xs: '300px', sm: '360px', md: '400px' },
                        gap: '1.5rem',
                      }}
                    >
                      <TextField
                        
                        fullWidth
                        margin="normal" 
                        id="codigo"
                        name="codigo"
                        label="codigo"
                        autoComplete='off'
                        value={formik.values.codigo}
                        onChange={formik.handleChange}
                        error={formik.touched.codigo && Boolean(formik.errors.codigo)}
                        helperText={formik.touched.codigo&& formik.errors.codigo}
                      />
                    
                      <TextField
                        fullWidth
                        margin="normal" 
                        id="descripcion"
                        name="descripcion"
                        label="descripcion"
                        autoComplete='off'
                        value={formik.values.descripcion}
                        onChange={formik.handleChange}
                        error={formik.touched.descripcion && Boolean(formik.errors.descripcion)}
                        helperText={formik.touched.descripcion && formik.errors.descripcion}
                      />
                      <TextField
                        fullWidth
                        margin="normal" 
                        type="number"
                        id="folioinicial"
                        name="folioinicial"
                        label="folio inicial"
                        value={formik.values.folioinicial}
                        onChange={formik.handleChange}
                        error={formik.touched.folioinicial && Boolean(formik.errors.folioinicial)}
                        helperText={formik.touched.folioinicial && formik.errors.folioinicial}
                      />   
                      <TextField
                        fullWidth
                        margin="normal" 
                        type="number"
                        id="foliofinal"
                        name="foliofinal"
                        label="folio final"
                        value={formik.values.foliofinal}
                        onChange={formik.handleChange}
                        error={formik.touched.foliofinal && Boolean(formik.errors.foliofinal)}
                        helperText={formik.touched.foliofinal && formik.errors.foliofinal}
                      />      
                      <TextField
                        fullWidth
                        margin="normal" 
                        type="number"
                        id="siguiente"
                        name="siguiente"
                        label="siguiente"
                        value={formik.values.siguiente}
                        onChange={formik.handleChange}
                        error={formik.touched.siguiente && Boolean(formik.errors.siguiente)}
                        helperText={formik.touched.siguiente && formik.errors.siguiente}
                      /> 
                      <TextField
                        fullWidth
                        margin="normal" 
                        id="sistemaimpresora"
                        name="sistemaimpresora"
                        label="sistema impresora"
                        autoComplete='off'
                        value={formik.values.sistemaimpresora}
                        onChange={formik.handleChange}
                        error={formik.touched.sistemaimpresora && Boolean(formik.errors.sistemaimpresora)}
                        helperText={formik.touched.sistemaimpresora && formik.errors.sistemaimpresora}
                      /> 
                                                                                             
                    </Stack>
                      <DialogActions sx={{ p: '1.25rem' }}>
                        <Button color="secondary" onClick={onClose}>Cancel</Button>
                        <Button color="primary" type="submit" variant="contained">
                          Submit
                        </Button>
                       </DialogActions>
                 
                </form>
           </DialogContent>
        </Dialog>
      </>
    );
  };
  
  
  export default ModalDocumento;
  