
export const inicialUser={
  id:'new',
  firstName: '',
  lastName: '',
  email: '',
  age: 10,
  state: '',
  ciudad:{id:'' ,nombre:''},
  urlimagen:'../../assets/blanco.png'
};
export const inicialFamilia={
  id:'new',
  descripcion: '',
  codigo: '',
  title:'Familia producto',
  subtitle:'Mantencion tabla de familia de productos'
};
export const inicialSubFamilia={
  id:'new',
  descripcion: '',
  codigo: '',
  familia_producto:{id:'' ,descripcion:''},
  agregadosubfamiliaproducto:{id:'' ,descripcion:''},
  title:'SubFamilia producto',
  subtitle:'Mantencion tabla de sub-familia de productos'
};
export const inicialAgregadoSubFamilia={
  id:'new',
  descripcion: '',
  codigo: '',
  title:'Agregado sub  familia Producto',
  subtitle:'Mantencion tabla de agregado sub familia de productos'
};
export const inicialZona={
  id:'new',
  descripcion: '',
  codigo: '',
  title:'Zona',
  subtitle:'Mantencion tabla de zona'
};
export const inicialSector={
  id:'new',
  descripcion: '',
  codigo: '',
  title:'Sector',
  subtitle:'Mantencion tabla de sector'
};
export const inicialBodega={
  id:'new',
  descripcion: '',
  codigo: '',
  title:'Bodega',
  subtitle:'Mantencion tabla de bodega'
};
export const inicialDepartamento={
  id:'new',
  descripcion: '',
  codigo: '',
  title:'Departamento',
  subtitle:'Mantencion tabla de departamento'
};
export const inicialCentroConsumo={
  id:'new',
  descripcion: '',
  codigo: '',
  title:'Centro consumo',
  subtitle:'Mantencion tabla de centro consumo'
};
export const inicialOpcion={
  id:'new',
  descripcion: '',
  codigo: '',
  title:'Opcion',
  subtitle:'Mantencion tabla de opcion'
};
export const inicialAccionTipoDescuento={
  id:'new',
  descripcion: '',
  codigo: '',
  title:'Accion tipo descuento',
  subtitle:'Mantencion tabla de accion tipo descuento'
};
export const inicialTipoProducto={
  id:'new',
  descripcion: '',
  codigo: '',
  title:'Tipo producto',
  subtitle:'Mantencion tabla tipo producto'
};
export const inicialFamiliaArticulo={
  id:'new',
  descripcion: '',
  codigo: '',
  title:'Familia articulo',
  subtitle:'Mantencion tabla familia articulo'
};
export const inicialSubFamiliaArticulo={
  id:'new',
  descripcion: '',
  codigo: '',
  familiaarticulo:{id:'' ,descripcion:''},
  title:'SubFamilia articulo',
  subtitle:'Mantencion tabla de sub-familia de articulo'
};
export const inicialEstadoMesa={
  id:'new',
  descripcion: '',
  codigo: '',
  title:'Estado Mesa',
  subtitle:'Mantencion tabla de estado mesa'
};
export const inicialPlaza={
  id:'new',
  descripcion: '',
  codigo: '',
  sector:{id:'' ,descripcion:''},
  title:'Plaza',
  subtitle:'Mantencion tabla de plaza'
};
export const inicialTerminal={
  id:'new',
  descripcion: '',
  codigo: '',
  funcion:'',
  programa:'',
  argumento:'',
  departamento:{id:'' ,descripcion:''},
  title:'Terminal',
  subtitle:'Mantencion tabla de terminal'
};
export const inicialCondicionPago={
  id:'new',
  descripcion: '',
  codigo: '',
  dias:0,
  title:'Condicion pago',
   subtitle:'Mantencion tabla de condicion de pago'
};

export const inicialImpresora={
  id:'new',
  codigo: '',
  descripcion: '',
  puerta:'',
  habilita:'',
  deshabilita:'',
  comandopre:'',
  comandopos:'',
  title:'Impresora',
  subtitle:'Mantencion tabla de impresora'
};

export const inicialVendedor={
  id:'new',
  descripcion: '',
  codigo: '',
  clave:'',
  descuentolinea:true,
  descuentototal:true,
  comision:0.0,
  title:'Vendedor',
  subtitle:'Mantencion tabla de vendedor'
};
export const inicialTipoDescuento={
  id:'new',
  descripcion: '',
  codigo: '',
  acciontipodescuento:{id:'' ,descripcion:''},
  title:'Tipo descuento',
  subtitle:'Mantencion tabla de tipo descuento'
};
export const inicialDescuento={
  id:'new',
  codigo: '',
  descripcion: '',
  tipodescuento:{id:'' ,descripcion:''},
  title:'Descuento',
  subtitle:'Mantencion tabla de descuento'
};
export const inicialDocumento={
  id:'new',
  descripcion: '',
  codigo: '',
  folioinicial:0,
  foliofinal:0,    
  siguiente:0, 
  sistemaimpresora:'',
  lock:true,
  title:'Documento',
  subtitle:'Mantencion tabla de documento'
};

export const inicialMesa={
  id:'new',
  codigo: '',
  asientos:0,
  plaza:{id:'' ,descripcion:''},
  estadomesa:{id:'' ,descripcion:''},
  title:'Mesa',
  subtitle:'Mantencion tabla de mesa'
};
export const inicialListaPrecio={
  id:'new',
  codigo: '',
  descripcion: '',
  condicionpago:{id:'' ,descripcion:''},
  factor:0,
  title:'Lista precio',
  subtitle:'Mantencion tabla de lista precio'
};

export const inicialCentroProduccion={
  id:'new',
  codigo: '',
  descripcion: '',
  impresora:{id:'' ,descripcion:''},
  bodega:{id:'' ,descripcion:''},
  title:'Centro produccion',
  subtitle:'Mantencion tabla de centro produccion'
};

export const inicialImpresoraDocumentoFolio={
  id:'new',
  folioinicial:0,
  foliofinal:0,    
  siguiente:0, 
  impresora:{id:'' ,descripcion:''},
  documento:{id:'' ,descripcion:''},
  lock:true,
  title:'Impresora documento folio',
  subtitle:'Mantencion tabla de impresora documento folio'
};
export const inicialComunaSii={
  id:'new',
  descripcion: '',
  codigo: '',
  codigosii:'',
  title:'Comuna Sii',
  subtitle:'Mantencion tabla de comuna sii'
};
export const inicialArticulo={
  id:'new',
  descripcion: '',
  codigo: '',

  codigodebarra:'',
  unidaddemedida:'',
  ubicacion:'',
  
  preciodeventa:0,
  costopromedio:0,
  maximodescuento:0,
  modificapreciodeventa:false,
  stockmin:0,
  stockmax:0,

  fechaultimaventa:'',
  ventasdelmes:0,
  ventasacumuladas:0,

  fechacumayor:'',
  cunipromedio:0,
  cunitmayor:0,
  costostock:0,
  stocktotal:0,

  fechaultimacompra:'',
  comprasdelmes:0,
  comprasacumuladas:0,
  
  subfamilia_articulo:{id:'' ,descripcion:''},

  bodega:{id:'' ,descripcion:''},
  base:false,
  urlimagen:'../../assets/blanco.png',
  title:'Articulo',
  subtitle:'Mantencion tabla de articulo'

};

export const inicialProducto={

  id:'new',
  descripcion: '',
  codigo: '',
  codigoingresocomanda:'',  
  unidaddemedida:'',
  preciodeventa:0,
  modificapreciodeventa:false,

  costopromedio:0,
  fechaultimaventa:'',
  maximodescuento:0,
  ventasdelmes:0,
  ventasacumuladas:0,

  subfamilia_producto:{id:'' ,descripcion:''},
  tipoproducto:{id:'' ,descripcion:''},

  urlimagen:'../../assets/blanco.png',
  title:'Producto',
  subtitle:'Mantencion tabla de producto'

};

export const inicialProveedor={

  id:'new',
  rut:'',
  codigo:'',
  razonsocial: '',
 
  giro:'',
  direccion:'',
  ciudad:'',
  
  telefono:'',
  email:'',
  nombre:'',

  ultimacompra:0,
  comprames:0,

  title:'Proveedor',
  subtitle:'Mantencion tabla de proveedores'
};
export const inicialCliente={

  id:'new',
  rut:'',
  codigo:'',
  razonsocial: '',
 
  giro:'',
  direccion:'',
  ciudad:'',
  
  telefono:'',
  email:'',
  nombre:'',

  ultimaventa:0,
  ventames:0,

  condicionpago:{id:'' ,descripcion:''},


  title:'Cliente',
  subtitle:'Mantencion tabla de clientes'
};


export const statescid = [
  {id:'Alabama' ,nameState:'Alabama'},
  {id:'Alaska',nameState:'Alaska'},
  {id:'Arizona',nameState:'Arizona'},
  {id:'Arkansas',nameState:'Arkansas'},
  {id:'California',nameState:'California'},
  {id:'Colorado',nameState:'Colorado'},
  {id:'Connecticut',nameState:'Connecticut'},
];
//50 us states array
export const states = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
  'Puerto Rico',
];
export const currencies = [
  {
    value: "USD",
    label: "$"
  },
  {
    value: "EUR",
    label: "€"
  },
  {
    value: "BTC",
    label: "฿"
  },
  {
    value: "JPY",
    label: "¥"
  }
];

export const data  = [
  {
    id: '9s41rp',
    firstName: 'Kelvin',
    lastName: 'Langosh',
    email: 'Jerod14@hotmail.com',
    age: 19,
    state: 'Ohio',
  },
  {
    id: '08m6rx',
    firstName: 'Molly',
    lastName: 'Purdy',
    email: 'Hugh.Dach79@hotmail.com',
    age: 37,
    state: 'Rhode Island',
  },
  {
    id: '5ymtrc',
    firstName: 'Henry',
    lastName: 'Lynch',
    email: 'Camden.Macejkovic@yahoo.com',
    age: 20,
    state: 'California',
  },
  {
    id: 'ek5b97',
    firstName: 'Glenda',
    lastName: 'Douglas',
    email: 'Eric0@yahoo.com',
    age: 38,
    state: 'Montana',
  },
  {
    id: 'xxtydd',
    firstName: 'Leone',
    lastName: 'Williamson',
    email: 'Ericka_Mueller52@yahoo.com',
    age: 19,
    state: 'Colorado',
  },
  {
    id: 'wzxj9m',
    firstName: 'Mckenna',
    lastName: 'Friesen',
    email: 'Veda_Feeney@yahoo.com',
    age: 34,
    state: 'New York',
  },
  {
    id: '21dwtz',
    firstName: 'Wyman',
    lastName: 'Jast',
    email: 'Melvin.Pacocha@yahoo.com',
    age: 23,
    state: 'Montana',
  },
  {
    id: 'o8oe4k',
    firstName: 'Janick',
    lastName: 'Willms',
    email: 'Delfina12@gmail.com',
    age: 25,
    state: 'Nebraska',
  },
];
