import React, { useCallback, useMemo, useState ,useEffect,useContext} from 'react';
import axios from "axios";

import Swal from "sweetalert2"; 

import Header from "../../components/Header";


import MaterialReactTable ,
{ MRT_FullScreenToggleButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleGlobalFilterButton, } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import {
  Alert,
  Box,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';

import Snackbar from '@mui/material/Snackbar';
import { Delete, Edit,Add } from '@mui/icons-material';
import { inicialDescuento} from '../../data/makeDataCrud';
import CloudDownload from "@mui/icons-material/CloudDownload";
import Print from "@mui/icons-material/Print";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import {  ActionIcon } from '@mantine/core';

import {ModalDescuento} from './modal/ModalDescuento';

import { AuthContext } from "../../mycontext";

const URL_BASE=process.env.REACT_APP_URL_BASE;

const API_SEL_TDE=process.env.REACT_APP_API_SEL_TDE;

const API_SEL=process.env.REACT_APP_API_SEL_DES;
const API_INS=process.env.REACT_APP_API_INS_DES;
const API_UPD=process.env.REACT_APP_API_UPD_DES;
const API_DEL=process.env.REACT_APP_API_DEL_DES;

const Descuento= () => {
    const {user} = useContext(AuthContext);
    const { token } = user;  

    const [titulomod, setTitulomod] = useState('');
  
    const [title, setTitle] = useState('');
  
    const [subTitle, setSubTitle] = useState('');
  
    const [isRefetching, setIsRefetching] = useState(false);
  
    const [isLoading, setIsLoading] = useState(false);
    
    const [errorPrueba, setErrorPrueba] = useState(false);
    
    const [errorText, setErrorText] = useState("");

    const [createModalOpen, setCreateModalOpen] = useState(false);

    const [tableData, setTableData] = useState([]);

    const [tipodescuentoData, setTipodescuentoData] = useState([]);
  
    const [rowData, setRowData] = useState([]);

    const [inicial, setInicial] = useState(inicialDescuento); 


    //snack
    const [state] = useState({
      vertical: 'top',
      horizontal: 'center',
    });

    const { vertical, horizontal } = state;
    const [openSnack, setOpenSnack] = useState(false);
    const [message, setMessage] = useState(false);
   


    const handleCreateNewRow = (values) => {
    
  
          setIsLoading(true);
          setIsRefetching(true);
          
          const idtde=tipodescuentoData.find(element=>element.descripcion===values.tipodescuento);
          console.log("id sector ",idtde);


          axios
              .post(`${URL_BASE}${API_INS}`, {

                data: {
                  codigo: values.codigo,
                  descripcion: values.descripcion,
                  tipodescuento: idtde.id,
                  
                },
              },{    headers:{
                Authorization:`Bearer  ${token}`
              }})
              .then((response) => {
          
                console.log("reemplazando id =========>",response.data.data.id);  
                values.id=response.data.data.id;
                values.tipodescuento=response.data.data.tipodescuento;
                console.log("reemplazando tipodescuento =========>",values.tipodescuento);  
        
                            
                tableData.push(values);
                setTableData([...tableData]);
                
                
                setMessage(`Agregado ${values.id}`);
                setOpenSnack(true);

                setIsLoading(false);
                setIsRefetching(false);
              
            }).catch((error) => {

              console.log(error);
              setErrorPrueba(true);
              setErrorText(JSON.stringify(error));
              setIsLoading(false);
              setIsRefetching(false);


          });



    };
   

    const handleEdit= async (values ) => {


        console.log("modifica=====ok");
           
        setIsLoading(true);
        setIsRefetching(true);
      
        const idtde=tipodescuentoData.find(element=>element.descripcion===values.tipodescuento);
        
        console.log("id tipodescuento ",idtde);
        values.tipodescuento={id:idtde.id,descripcion:idtde.descripcion};

        tableData[rowData.index] = values;

          axios
          .put(`${URL_BASE}${API_UPD}${rowData.getValue('id')}`, {
            data: {
             
                codigo: values.codigo,
                descripcion: values.descripcion,
                sector: idtde.id,
               

            },
          },{    headers:{
            Authorization:`Bearer  ${token}`
          }})
          .then((response) => {
            console.log(response);

            setIsLoading(false);
            setIsRefetching(false);
            setMessage(`Modificado ${values.id}`);
            setOpenSnack(true);
 
        }).catch((error) => {

          console.log(error);
          setErrorPrueba(true)
          setErrorText(JSON.stringify(error));

        });
          
        setTableData([...tableData]);
         
    };


   const handleDeleteRow = useCallback(

      (row) => {
      
        console.log("id a borrar: ",row.getValue('id'));


       Swal.fire({  
        title: 'Seguro quiere borrar?',  
        
        icon: 'warning',  
        showCancelButton: true,  
        confirmButtonColor: '#3085d6',  
        cancelButtonColor: '#d33',  
        cancelButtonText: 'No',
        confirmButtonText: 'Si!'  

      }).then(response=>{

          if (response.isConfirmed){
            setIsLoading(true);
            setIsRefetching(true);
    
            //send api delete request here, then refetch or update local table data for re-render
            
            axios
            .delete(`${URL_BASE}${API_DEL}${row.getValue('id')}`,{    headers:{
              Authorization:`Bearer  ${token}`
            }})
           
            .then((response) => {
                console.log(response);
    
                tableData.splice(row.index, 1);
                setTableData([...tableData]);
    
                setIsLoading(false);
                setIsRefetching(false);
    
                setMessage(`Eliminado ${row.getValue('id')}`);
                setOpenSnack(true);
                
            })
            .catch((error) => {
                setIsLoading(false);
                setIsRefetching(false);
    
                console.log(error);
                setErrorPrueba(true);
                setErrorText(JSON.stringify(error))
    
            });             

          };

      });


      },
      [tableData,token],
    );

    const columns = useMemo(
      () => [
        {
          accessorKey: 'id',
          header: 'ID',
          enableColumnOrdering: false,
          enableEditing: false, //disable editing on this column
          enableSorting: false,
          size: 80,
        },
        {
          accessorKey: 'codigo',
          header: 'codigo',
          size: 140,

        },
        {
          accessorKey: 'descripcion',
          header: 'descripcion',
          size: 140,

        },
        {
          accessorKey: 'tipodescuento.descripcion',
          header: 'tipo descuento',
          size: 140,

        },
                
        
      ],
      [],
    );
  //       accessorFn: (row) => `${row.familia_producto.codigo} ${row.familia_producto.descripcion}`,
  
  //  accessorKey: 'familia_producto.descripcion',
  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);

  };

 //const columnsCsv=['First Name','Last Name','Addres','City','State']
 const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: columns.map((c) => c.header),
};
  
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    let dataCsv=[];

    for(let i = 0;i<tableData.length;i++) { 

       const fila={id: tableData[i].id ,
                   codigo: tableData[i].codigo ,
                   descripcion: tableData[i].descripcion,
                   tipodescuento: tableData[i].sector.descripcion,
                    };
       dataCsv=[...dataCsv,fila];
      
    };
 
    csvExporter.generateCsv(dataCsv);
  };


  const get_data_to_pdf=()=>{
    let dataPdf=[];

    for(let i = 0;i<tableData.length;i++) { 

       const fila=[ tableData[i].id, 
                    tableData[i].codigo ,
                    tableData[i].descripcion,
                    tableData[i].tipodescuento.descripcion,
                
       ];
       dataPdf=[...dataPdf,fila];
      
    };

    return dataPdf;
 
  }

  const get_column_to_pdf=()=>{
    let columnPdf=[];

    for(let i = 0;i<columns.length;i++) { 

       columnPdf=[...columnPdf,columns[i].header];
      
    };

    return columnPdf;
  }  

  const downloadPdf=()=>{
    const doc = new jsPDF()

    autoTable(doc, { html: '#my-table' })
   
    doc.text( title,15,10);
    autoTable(doc, {
      
      head: [get_column_to_pdf()],
      body: get_data_to_pdf(),
      
    })

    doc.save('table.pdf')
  };  

  useEffect(() => { 

   setTitulomod(''); 
   setTitle(inicialDescuento.title);
   setSubTitle(inicialDescuento.subtitle);
   setIsLoading(true);
   setIsRefetching(true);

   const cargaTdescuento=async()=>{
  
    await axios
    .get(`${URL_BASE}${API_SEL_TDE}`,{    headers:{
      Authorization:`Bearer  ${token}`
    }})
    .then(({ data }) => {
     
      setTipodescuentoData(data.data);

      setIsLoading(false);
      setIsRefetching(false);

    })
    .catch((error) => {
      console.log("error")
      setErrorPrueba(true)
      setErrorText(JSON.stringify(error))
     
    });

  };
  
   const urlapi=`${URL_BASE}${API_SEL}`
   console.log(urlapi);

   const cargaStrapi=async()=>{

       await axios
       .get(urlapi)
       .then(({ data }) => {

         console.log(data.data);
        
         setTableData(data.data);   

         cargaTdescuento();
        

       })
       .catch((error) => {
         console.log("error")
         setErrorPrueba(true)
         setErrorText(JSON.stringify(error))
       
       });

  }; 

   cargaStrapi();
   
 },[token] );  
  return (
    <>
 
     {  errorPrueba   &&
        <Alert onClose={() => setErrorPrueba(false)} variant="outlined" severity="error">
            {errorText} This is an error alert — check it out!
        </Alert>
      }  

      <Snackbar open={openSnack}
       autoHideDuration={3000} 
       onClose={handleClose}  

       anchorOrigin={{ vertical, horizontal }}
       key={vertical + horizontal}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
             {message}
        </Alert>
      </Snackbar>

      <Box m="20px">
        <Header
          title={title}
          subtitle={subTitle}
        />
      </Box>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}

        localization={MRT_Localization_ES}
        enableTopToolbar={true} //hide top toolbar
        enableBottomToolbar={true} //hide bottom toolbar
        renderToolbarInternalActions={({ table }) => (
          <>
            <ActionIcon
                      onClick={() => {
                        
                        // window.print();
                        downloadPdf();

                      }}
                    >
                      <Print />
            </ActionIcon>

            <ActionIcon
                onClick={() => {
                  handleExportData();
                }}
              >
                <CloudDownload/>
            </ActionIcon>

            {/* built-in buttons (must pass in table prop for them to work!) */}
            <MRT_ToggleGlobalFilterButton table={table}/>
            <MRT_ShowHideColumnsButton table={table} />
            <MRT_FullScreenToggleButton table={table} />
          </>
        )}

        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        state={{
          isLoading,
          showProgressBars: isRefetching
        }}      
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() =>{

                 console.log("modificando");
                 setTitulomod(`${title} Modifica registro Id ${row.original.id}`);
                 setRowData(row);  

                 const modificar={
                  id: row.original.id,
                  codigo: row.original.codigo,
                  descripcion:  row.original.descripcion,
                  tipodescuento:  row.original.tipodescuento,
                 

                };
                
                console.log(modificar);
                setInicial(modificar);
                setCreateModalOpen(true);

              }
               
              }> 
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button
            color="secondary"
            onClick={() => {
              setInicial(inicialDescuento);
              setTitulomod(`${title} Nuevo registro`);
              
              setCreateModalOpen(true)}}
            variant="contained"
          >
              <Add/>
          </Button>
        )}
      />
      
      <ModalDescuento
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
        onEdit={handleEdit}
        tipodescuentoData={tipodescuentoData}
        inicial={inicial}
        tableData={tableData}
        titulomod={titulomod}
        

      />

    </>
  );
};

export default Descuento;
